import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() { return [ "input", "source", "id", "externalId", "fullName", "insurance" ] }
  
  connect() {
    const that = this;
    $('#patientSearchModal').on('shown.bs.modal', function (e) {
      that.inputTarget.focus();
    })
  }

  change(event) {
    this.idTargets.forEach(target => target.value = this.sourceTarget.dataset['patientId'])
    this.externalIdTargets.forEach(target => target.value = this.sourceTarget.dataset['patientExternalId'] || '')
    this.fullNameTargets.forEach(target => target.innerHTML = this.sourceTarget.dataset['patientFullName'] || '')
    this.insuranceTargets.forEach(target => target.innerHTML = this.sourceTarget.dataset['patientInsurance'] || '')
    this.hide();
  }

  hide() {
    $('#patientSearchModal').modal('hide')
  }

  show() {
    $('#patientSearchModal').modal('show')
  }
}