import { Controller } from "@hotwired/stimulus"
import * as $ from "jquery"

const colorCodes = {
  A1: '#dcceab',
  A2: '#dcc694',
  A3: '#dbc289',
  A35: '#d5b97a',
  A4:'#c7a76a',
  B1: '#ddd3b0',
  B2: '#dac896',
  B3: '#d6bd7d',
  B4: '#d4b877',
  C1: '#cec099',
  C2: '#cbb689',
  C3: '#c9b382',
  C4: '#c0a160',
  D2: '#d1c2a1',
  D3: '#cdba92',
  D4: '#cfb97f',
}
export default class extends Controller {
  static targets = [
    "modal",
    "tooth",

    // form input elements
    "treatmentInput",
    "mountingInput",
    "linkInput",
    "veneerInput",
    "primaryColorInput",
    "secondaryColorInput",
    "translucentColorInput",

    // preview elements
    "preview",
    "colorPreview",
    "primaryColorPreview",
    "primaryColorPreviewText",
    "secondaryColorPreview",
    "secondaryColorPreviewText",
    "translucentColorPreviewText",
  ]

  connect() {
    this.update();
  }

  showModal(){
    // TODO set all values from last stuff
    $(this.modalTarget).modal()
  }

  saveModal() {
    $(this.modalTarget).modal('hide')
  }

  update() {
    // Enable/Disable Inputs
    this.setVeneerInput();
    this.setColorInputs();
    this.setMountingInput();
    this.setLinkInput();

    // Update Previews
    this.updatePreview();
    this.updateColorPreview();
  }

  setVeneerInput() {
    if(!this.treatmentInputTarget.value) {
      this.veneerInputTarget.disabled = true;
      this.veneerInputTarget.value = '';
    } else {
      this.veneerInputTarget.disabled = false;
    }
  }

  setColorInputs() {
    if (!!this.veneerInputTarget.value) {
      this.primaryColorInputTarget.disabled = false;
      this.secondaryColorInputTarget.disabled = false;
      this.translucentColorInputTarget.disabled = false;

      this.colorPreviewTarget.style.display = 'block'
    } else {
      this.primaryColorInputTarget.disabled = true;
      this.primaryColorInputTarget.value = '';

      this.secondaryColorInputTarget.disabled = true;
      this.secondaryColorInputTarget.value = '';

      this.translucentColorInputTarget.checked = false;
      this.translucentColorInputTarget.disabled = true;

      this.colorPreviewTarget.style.display = 'none'
    }
  }

  setMountingInput() {
    if(!this.treatmentInputTarget.value) {
      this.mountingInputTarget.disabled = true;
      this.mountingInputTarget.value = '';
    } else {
      this.mountingInputTarget.disabled = false;
    }
  }
  setLinkInput() {
    if(!this.treatmentInputTarget.value) {
      this.linkInputTarget.disabled = true;
      this.linkInputTarget.value = '';
    } else {
      this.linkInputTarget.disabled = false;
    }
  }

  updatePreview() {
    // TODO use hotwire
    let preview = '-';

    if (!!this.treatmentInputTarget.value) {

      preview = `${this.mountingInputTarget.value}${this.linkInputTarget.value}${this.treatmentInputTarget.value}${this.veneerInputTarget.value}`
      
      this.toothTarget.classList.remove('bg-light');
      this.toothTarget.classList.add('bg-primary');
      this.toothTarget.classList.add('font-white');
    } else {
      this.toothTarget.classList.add('bg-light');
      this.toothTarget.classList.remove('bg-primary');
      this.toothTarget.classList.remove('font-white');
    }

    this.previewTargets.forEach(element => {
      element.textContent = preview
    });
  }

  updateColorPreview() {
    this.updatePrimaryColorPreview();
    this.updateSecondaryColorPreview();
    this.updateTranslucentColorPreview();
  }

  updatePrimaryColorPreview() {
    const code = this.primaryColorInputTarget.value;
    const label = this.primaryColorInputTarget.options[this.primaryColorInputTarget.selectedIndex].text

    this.primaryColorPreviewTargets.forEach(element => {
      element.style.backgroundColor = colorCodes[code] || 'transparent'
    });

    this.primaryColorPreviewTextTargets.forEach(element => {
      element.innerHTML = label || null;
    });
  }

  updateSecondaryColorPreview() {
    const code = this.secondaryColorInputTarget.value;
    const codeWithFallback = code || this.primaryColorInputTarget.value || null;
    const label = this.secondaryColorInputTarget.options[this.secondaryColorInputTarget.selectedIndex].text
  
    this.secondaryColorPreviewTargets.forEach(element => {
      element.style.backgroundColor = colorCodes[codeWithFallback] || 'transparent'
    });
    this.secondaryColorPreviewTextTargets.forEach(element => {
      element.innerHTML = label || null;
    });
  }

  updateTranslucentColorPreview() {
    const value = this.translucentColorInputTarget.checked;

    this.translucentColorPreviewTextTargets.forEach(element => {
      element.innerHTML = value ? "T" : "";
    })
  }
}
