import { Controller } from "@hotwired/stimulus"
import debounce from 'lodash/debounce';

export default class extends Controller {
  static get targets() { return [ "submit", "spinner" ] }
  
  initialize(){
    this.submit = debounce(this.submit, 300).bind(this)
  }

  connect() {
    this.submitTarget.hidden = true

    var that = this;
    this.element.addEventListener('turbo:before-fetch-request', function(e) {
      that.showSpinner()
    });
    this.element.addEventListener('turbo:before-fetch-response', function(e) {
      that.hideSpinner()
    });

    this.submit()
  }

  submit() {
    this.submitTarget.click();
  }

  showSpinner() {
    this.spinnerTarget.classList.remove("invisible");
  }

  hideSpinner() {
    this.spinnerTarget.classList.add("invisible");
  }
}