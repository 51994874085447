
import { Controller } from "@hotwired/stimulus"
import Combobox from '@github/combobox-nav'

export default class extends Controller {
  static get targets() { return [ "input", "list", "result" ] }

  connect() {
    
  }

  disconnect() {
    this.combobox?.destroy()
  }

  listTargetConnected() {
    this.start()

    const that = this;
    this.listTarget.addEventListener('combobox-commit', function(event) {
      that.update(event)
    })
  }

  start() {
    this.combobox?.destroy()
    this.combobox = new Combobox(this.inputTarget, this.listTarget)
    this.combobox.start()
  }

  stop() {
    this.combobox?.stop()
  }

  update(event) {
    this.resultTarget.value = event.target.id
    for(var key in event.target.dataset) {
      this.resultTarget.dataset[key] = event.target.dataset[key];
    }
    this.resultTarget.dispatchEvent(new Event('change'));
  }

  clearSelection() {
    this.combobox?.clearSelection()
  }
}