// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()

import "@hotwired/turbo-rails"
Turbo.session.drive = false

import "@hotwired/stimulus"
import "controllers"
import "trix"
import "@rails/actiontext"

require("../stylesheets/application")
import 'bootstrap';

// Export jQuery globally
import * as $ from "jquery"
window.$ = $;